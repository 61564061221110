<template>
  <div class="aCupOfCoffee">
    <!-- 搜索 -->
    <div class="search">
      <div class="searchBox">
        <div class="search-l">
          <input
            v-model="requestData.demandDesc"
            type="text"
            placeholder="搜索"
            @blur="handelSearch"
          />
        </div>
        <div class="search-r" @click="search()">搜索</div>
      </div>
      <div class="code" @click="scanQrCodes">
        <img src="./img/code.png" alt="" />
      </div>
    </div>
    <!-- 分类 -->
    <div class="tabList">
      <div
        @click="tabChoose(t)"
        :class="['tab-item', { 'tab-item-act': t.select }]"
        v-for="(t, i) in tabList"
        :key="i"
      >
        {{ t.name }}
      </div>
    </div>
    <!-- 列表 -->
    <div class="content">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="list"
          v-for="(item, index) in listData"
          :key="index"
          @click="handelClickGoDetail(item)"
        >
          <div class="list-l">
            <div class="name">姓名：{{ item.realName }}</div>
            <div class="name">需求：{{ item.demandDesc }}</div>
          </div>
          <div class="list-r">
            <img :src="(item.imageUrl || '').split(',')[0]" alt="" />
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { findCoffeeDemandPage } from "./api.js";
import { origin } from "../../../../public/config/request";
import wx from "weixin-js-sdk";
import { initWxJsSdkURL } from "./api.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
export default {
  name: "ldleTransaction",
  data() {
    return {
      keysWord: "",
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      requestData: {
        curPage: 1,
        pageSize: 10,
        demandType: "",
        demandDesc: "",
      },
      tabList: [
        { name: "全部", value: "", select: true },
        { name: "交友", value: "交友", select: false },
        { name: "旅行", value: "旅行", select: false },
        { name: "创业", value: "创业", select: false },
        { name: "培训", value: "培训", select: false },
      ],
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.initWxConfig();
  },
  async mounted() {
    if (await toRegister(this.$route.path, this.$route.query, "一杯咖啡")) {
    }
  },
  methods: {
    handelSearch() {
      this.requestData.curPage = 1;
      this.onLoad();
    },
    onLoad() {
      this.getFindCoffeeDemandPage();
    },
    //requestData
    getFindCoffeeDemandPage() {
      this.$axios
        .get(findCoffeeDemandPage, {
          params: this.requestData,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.requestData.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.requestData.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.requestData.curPage++;
            }
          }
        });
    },
    // 微信授权H5可调用的 wx API功能
    async initWxConfig() {
      const url =
        process.env.NODE_ENV == "production"
          ? location.href.split("#")[0]
          : origin;
      let res = await this.$axios.post(
        `${initWxJsSdkURL}`,
        this.$qs.stringify({
          url,
        })
      );
      if (res.code == 200) {
        // this.wxData = res.data;
        const result = res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: ["scanQRCode"], // 必填，需要使用的 JS 接口列表
        });
      }
    },
    //搜索
    search() {},
    //扫码
    scanQrCodes() {
      wx.ready(() => {
        console.log("wx ready---");
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: (res) => {
            if (res.resultStr) {
              const obj = {
                jiaoyou: "交友",
                lvxing: "旅行",
                chuangye: "创业",
                peixun: "培训",
              };
              let data = obj[res.resultStr];
              this.$router.push({
                name: "registerInfo",
                query: {
                  type: data,
                },
              });
            }
          },
        });
      });
    },
    //切换tab
    tabChoose(t) {
      this.tabList.forEach((item) => {
        item.select = false;
      });
      t.select = true;
      this.requestData.demandType = t.value;
      this.requestData.curPage = 1;
      this.onLoad();
    },
    //详情页面
    handelClickGoDetail(item) {
      this.$router.push({
        name: "userInfoDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.aCupOfCoffee {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 24px 30px 0 30px;
  padding-bottom: calc(120px + constant(safe-area-inset-bottom));
  padding-bottom: calc(120px + env(safe-area-inset-bottom));
  .search {
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 46px;
    .searchBox {
      flex: 1;
      max-width: calc(100% - 80px);
      height: 100%;
      background: #ffffff;
      border-radius: 37px;
      border: 4px solid #8a95a6;
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      .search-r {
        width: 150px;
        height: 100%;
        background: #8a95a6;
        border: 2px solid #8a95a6;
        text-align: center;
        // border-radius: 37px;
        border-top-left-radius: 37px;
        border-bottom-left-radius: 37px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 66px;
      }
      .search-l {
        flex: 1;
        height: 100%;
        padding: 0 48px;
        box-sizing: border-box;
        input {
          box-sizing: border-box;
          display: block;
          width: 100%;
          height: 66px;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #a0a6b9;
        }
      }
    }
    .code {
      width: 50px;
      height: 44px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .tabList::-webkit-scrollbar {
    display: none;
  }
  .tabList {
    height: 52px;
    display: flex;
    line-height: 52px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin-bottom: 46px;
    .tab-item-act {
      font-weight: 600;
      color: #ffffff;
      background: #a0a6b9 !important;
    }
    .tab-item {
      height: 100%;
      min-width: 154px;
      background: #f7f7f7;
      border-radius: 26px;
      text-align: center;
      margin-right: 20px;
    }
  }

  .list {
    width: 100%;
    min-height: 174px;
    background: #f9f9f9;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 18px 18px 18px 32px;
    box-sizing: border-box;
    &:not(:last-child) {
      margin-bottom: 22px;
    }
    .list-l {
      flex: 1;
      padding: 18px 32px 18px 0;
      box-sizing: border-box;
      div {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        &:first-child {
          margin-bottom: 24px;
        }
      }
    }
    .list-r {
      width: 140px;
      height: 140px;
      overflow: hidden;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
