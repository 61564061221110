//获取微信jssdk初始化
const initWxJsSdkURL = `/gateway/hc-mini/qrcode/getPASignature`;
//一杯咖啡需求管理查询条件
const findCoffeeDemandPage = `/gateway/hc-external/coffeeDemand/findCoffeeDemandPage`;
//保存或更新一杯咖啡需求管理
const saveOrUpdateCoffeeDemand = `/gateway/hc-external/coffeeDemand/saveOrUpdateCoffeeDemand`;
//根据ID获取一杯咖啡需求管理
const getCoffeeDemandById = `/gateway/hc-external/coffeeDemand/getCoffeeDemandById`;
//获取承诺书列表
const getLetterOfCommitmentList = `/gateway/hc-external/coffeeCommitment/findCoffeeCommitmentPage`;

export {
  initWxJsSdkURL,
  findCoffeeDemandPage,
  saveOrUpdateCoffeeDemand,
  getCoffeeDemandById,
  getLetterOfCommitmentList
};
